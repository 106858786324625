<template>
  <body>
    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <!-- Header Section -->
        <Front-Header> </Front-Header>

        <!-- Main Section -->

        <div class="feature-img-section user-profile"></div>

        <!-- Service section  -->

        <section class="package_includes user-profile-option">
          <div class="container">
            <div class="text-center">
              <h2 class="mb-10">Explore The Our Products</h2>
              <!-- Tab panes -->
              <div class="tab-content">
                <div class="tab-pane container active" id="Subscriptions">
                  <section id="entProductSection" class="home_products">
                    <div class="container">
                      <div class="row">
                        <div
                          class="col-md-4"
                          v-for="subscription in subscriptions"
                          :key="subscription.id"
                        >
                          <div class="product_details">
                            <a
                              href="/en-ae/dubai-2021"
                              onclick="showLoader()"
                              class="d-block"
                              tabindex="0"
                            ></a>
                            <div class="top-content">
                              <router-link
                                :to="{ path: '/subscriptions/'+ subscription.id}"
                                onclick="showLoader()"
                                class="d-block"
                                tabindex="0"
                              >
                                <div class="product_img">
                                  <img
                                    data-src="https://etsitecdn.theentertainerme.com/gallery/product2021/DXB+Classic-Product+d.png"
                                    alt="Dubai Classic 2021"
                                    class="img-fluid lazyloaded"
                                    :src="subscription.image"
                                  />
                                </div>
                              </router-link>
                              <router-link
                                :to="{ path: '/subscriptions/'+ subscription.id}"
                                tabindex="0"
                              >
                                <div
                                  class="product_details_inner d-flex flex-column justify-content-center"
                                >
                                  <h2 class="product_name m-0">
                                    {{ subscription.title }}
                                  </h2>
                                  <small
                                    class="short_desc"
                                    >{{ subscription.tag_line }}</small
                                  >
                                  <div class="product_price">
                                    <div class="tag-wrap">
                                      <span
                                        class="badge_transparent d-inline-flex align-items-center justify-content-center"
                                      >
                                        Special Offer</span
                                      >
                                    </div>
                                    <span class="strike"
                                      ><span class="before_price"
                                        >{{ subscription.currency }} {{ subscription.price }}</span
                                      ></span
                                    >
                                    <span class="percentage_off ml-1">
                                      {{ subscription.discount }} % Off
                                    </span>
                                    <h3 class="after_price">
                                      {{ subscription.currency }} {{ subscription.discountPrice }}

                                      <span class="vat_info"
                                        >*{{ subscription.currency }} {{ subscription.vat }} including
                                        VAT</span
                                      >
                                    </h3>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                            <div class="bottom-content">
                              <div class="d-flex">
                                <ul
                                  class="product_features list-unstyled d-flex flex-wrap flex-column p-0"
                                >
                                  <li
                                    v-for="tag in subscription.tags"
                                    :key="tag.value"
                                  >
                                    <span
                                      class="text d-block"
                                      >{{ tag.value }}</span
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      class="modal fade upsellmodal"
                    >
                      <div
                        role="document"
                        class="modal-dialog modal-dialog-centered"
                      >
                        <div class="modal-content">
                          <div class="modal-header modal-header-top p-0">
                            <button
                              type="button"
                              data-dismiss="modal"
                              aria-label="Close"
                              class="close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <!---->
                        </div>
                      </div>
                    </div>
                    <div
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      class="modal fade genericModalConsent separateProduct"
                    >
                      <div
                        role="document"
                        class="modal-dialog modal-dialog-centered"
                      >
                        <div class="modal-content">
                          <div class="modal-header modal-header-top p-0">
                            <button
                              type="button"
                              data-dismiss="modal"
                              aria-label="Close"
                              class="close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div class="modal-body text-center">
                            <p>
                              You already have products in your basket. Do you
                              wish to remove these and start a new order?
                            </p>
                            <div class="d-flex">
                              <!---->
                              <a
                                href="&amp;cartRefresh=1"
                                class="btn cheersCntBtn subscribe_btn"
                                >Yes</a
                              >
                              <a
                                href="javascript:void(0);"
                                data-dismiss="modal"
                                class="btn cheersCntBtn subscribe_btn"
                                >No</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      data-toggle="modal"
                      data-target=".upsellmodal"
                      data-keyboard="false"
                      data-backdrop="static"
                      class="tempUpsellBtn"
                      style="display: none"
                    ></button>
                    <button
                      data-toggle="modal"
                      data-target=".separateProduct"
                      data-keyboard="false"
                      data-backdrop="static"
                      class="tempSeparateBtn"
                      style="display: none"
                    ></button>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Main Section -->
        <!-- Footer Section -->
        <Front-Footer> </Front-Footer>
      </div>
    </div>
  </body>
</template>

<script>

import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';


import $ from 'jquery'

import { db } from '@/main'

export default{
     name: 'Profile',
        data(){
            return{
                item:{},
                selected: '',
                options: [],
                previewImage:'',//https://www.theentertainerme.com/images/profile_img2.jpg
                subscriptions: [],
                offers: [],
            }
        },
        components:{
            'Front-Header':Header,
            'Front-Footer':Footer,
            'Loader':Loader,


        },

        created: function(){
            this.getItem();
            this.getCategories();
            this.fetchSubscriptions();
            this.getOffers();
        },

        methods: {

            uploadImage(e){
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    console.log('dd');
                };
            },
            getItem()
            {
			

                var id = localStorage.getItem("userSessionId");
                console.log('sssss '+id);
                console.log('sssss');
                //var id = id;
                //var id = this.$route.params.id;

                /*db.collection('blogs').doc(id).get().then((doc) => {
                    console.log(doc.data());
                    this.item = doc.data();
                    this.previewImage = doc.data().image;
                })*/
                console.log('fssdfs');
                console.log(id);


                //db.collection("users").where("id", "==", id)
                //.get()
                db.collection('users').doc(id).get()
                .then((doc) => {

                    console.log('dddd');

                    console.log(doc.data());
                    this.item = doc.data();
                    this.previewImage = doc.data().image;
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

            },
            getCategories()
            {
                this.options = [];

                db.collection("categories")
                  .get()
                  .then((querySnapshot) => {
                    //console.log('hhhh');
                    querySnapshot.forEach((doc) => {
                      this.options.push({
                        id: doc.id,
                        name: doc.data().name,
                      });
                      //console.log(doc.id, " => ", doc.data());

                    });

                    //console.log(this.options);
                  })
                  .catch((error) => {
                    console.log("Error getting documents: ", error);
                  });
            },

            submit()
            {
                var id = localStorage.getItem("userSessionId");
                var  date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                console.log(this.item);
                db.collection("users")
                .doc(id)
                .update({
                  firstname: this.item.firstname,
                  lastname: this.item.lastname,
                  phone: this.item.phone,
                  email: this.item.email,
                  country: this.item.country,
                  nationality: this.item.nationality,
                  image:this.previewImage,
                  modified: date,
                })
                .then(() => {
                  console.log("Document successfully updated!");
                  //this.readEmployees();
                  this.$toasted.success('Success, Updated Successfully!', {
                    icon : 'check',
                     //icon : 'error_outline'
                 })
                 //this.$router.push('/profile');

                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                });
            },

    fetchSubscriptions()
    {


      db.collection("subscriptions")
      .get()
      .then((querySnapshot) => {
        //console.log('hhhh');
        querySnapshot.forEach((doc) => {

         // console.log(doc.id, " => ", doc.data());
           if(doc.data().country == localStorage.getItem("ciddata")){
              var discountPrice = doc.data().price - doc.data().price * doc.data().discount/100;
              var vat = discountPrice + discountPrice * doc.data().vat/100;
              if(doc.data().blogshowoption == 1){
              this.subscriptions.push({
                id: doc.id,
                title: doc.data().title,
                tag_line: doc.data().tag_line,
                tags: doc.data().tag,
                description: doc.data().description,
                image: doc.data().image,
                price: doc.data().price,
                currency: doc.data().currency,
                discount: doc.data().discount,
                discountPrice: discountPrice,
                vat: vat,
                created: doc.data().created,
              });
              }
           }

        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });


    },

  getOffers()
    {
       var uid = localStorage.getItem("userSessionId");

            db.collection('redeem_offers')
            .where("uid", "==", uid)
            .get().then((querySnapshot) => {
                querySnapshot.forEach((docs) => {

                  db.collection('offers')
                  //.where("uid", "==", uid)
                  .doc(docs.data().offer_id)
                  .get().then((doc) => {

                    db.collection('companies')
                  //.where("uid", "==", uid)
                  .doc(doc.data().company)
                  .get().then((res) => {

                    this.offers.push({
                        id: doc.id,
                        title: doc.data().title,
                        company_name: res.data().name,
                        amount: doc.data().amount,
                        is_reuseable: doc.data().is_reuseable,
                        tag: doc.data().tag,
                        valid_date: doc.data().valid_date,
                        image: doc.data().image,

                      });
                    });
                    });
                })

            })


    },

        },
	/* mounted() { 
			  let  Ustatus = localStorage.getItem("Ustatus");
				if(Ustatus == 2 )
				{
				   this.$router.replace({ name: "explore" });
				 } else if(Ustatus == 1) {
				   this.$router.replace({ name: "explore" });
				}else{
				 this.$router.replace({ name: "signin" });
				}
	  } */
    }


$('.blog-filter-btn').click(function() {
    $('.theiaStickySidebar').toggleClass("show");
});
</script>
